import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/landing/hero"
import Features from "../components/landing/features"
import Revolution from "../components/landing/revolution"
import OurHelp from "../components/landing/ourhelp"
import Customization from "../components/landing/customization"
import Libertypay from "../components/landing/libertypay"
import Contacts from "../components/landing/contact"

const IndexPage = () => (
  <Layout>
    <SEO title="Donation Box" />
    <Hero/>
    <Features/>
    <Revolution/>
    <OurHelp/>
    <Customization/>
    <Libertypay/>
    <Contacts/>
  </Layout>
)

export default IndexPage