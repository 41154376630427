import React from "react"
import customizeImg from "../../images/libertypay.png"
import { Box, Image, Heading, Paragraph, ResponsiveContext } from "grommet"

const LibertyPay = () => {
  const size = React.useContext(ResponsiveContext)
  const textAlign = {
    textAlign: size === "small" ? "center" : "start",
  }

  const basis = {
    basis: size === "small" ? "1" : "1/2",
  }

  return (
    <Box
      pad={{ horizontal: "xlarge", vertical: "xlarge" }}
      direction={size === "small" ? "column" : "row"}
    >
      {size === "small" && (
        <Box
          {...basis}
          margin={{ horizontal: "large" }}
          pad={{ horizontal: "large" }}
        >
          <a
            href="https://www.libertypay.co.uk/"
            taget="_blank"
            rel="noopener noreferrer"
          >
            <Image src={customizeImg} fill margin={{ bottom: "medium" }} />
          </a>
        </Box>
      )}

      <Box
        {...basis}
        margin={{ horizontal: "large" }}
        pad={{ horizontal: "large" }}
      >
        <Heading level="2" margin="none" textAlign="center" {...textAlign}>
          Una tecnologia sicura per tutte le organizzazioni
        </Heading>
        <Paragraph size="large" textAlign="center" {...textAlign}>
          Siamo il partner esclusivo per l’Italia di LibertyPay, leader mondiale
          per la gestione dei pagamenti di enti e organizzazioni non profit. Un
          partner sicuro ed affidabile che ha costruito una soluzione pensata
          proprio per la raccolta fondi.
        </Paragraph>
      </Box>

      {size !== "small" && (
        <Box
          {...basis}
          margin={{ horizontal: "large" }}
          pad={{ horizontal: "large" }}
          justify="center"
        >
          <a
            href="https://www.libertypay.co.uk/"
            taget="_blank"
            rel="noopener noreferrer"
          >
            <Image
              src={customizeImg}
              alignSelf="center"
              margin={{ bottom: "medium" }}
            />
          </a>
        </Box>
      )}
    </Box>
  )
}

export default LibertyPay
