import React from "react"
import customizeImg from "../../images/totem.png"
import { Box, Image, Heading, Paragraph, ResponsiveContext } from "grommet"

const Customization = () => {
  const size = React.useContext(ResponsiveContext)
  const textAlign = {
    textAlign: size === "small" ? "center" : "start",
  }

  const basis = {
    basis: size === "small" ? "1" : "1/2",
  }

  return (
    <Box
      pad={{ horizontal: "xlarge", vertical: "xlarge" }}
      direction={size === "small" ? "column" : "row"}
      background="light-2"
      align="center"
    >
      <Box
        {...basis}
        margin={{ horizontal: "large" }}
        pad={{ horizontal: "large" }}
        height="medium"
      >
        <Image
          src={customizeImg}
          fit="contain"
          alignSelf="center"
          height={200}
          margin={{ bottom: "medium" }}
        />
      </Box>

      <Box
        {...basis}
        margin={{ horizontal: "large" }}
        pad={{ horizontal: "large" }}
      >
        <Heading level="2" margin="none" textAlign="center" {...textAlign}>
          Infinite possibilità
        </Heading>
        <Paragraph size="large" textAlign="center" {...textAlign}>
          Con una varietà di display personalizzati, appositamente creati dai
          nostri partner, o dalla tua agenzia di fiducia, potrai accettare
          donazioni contactless senza necessità di presidio ed avrai il tuo
          brand sempre ben visibile.
        </Paragraph>
      </Box>
    </Box>
  )
}

export default Customization
