import React from "react"
import startupSvg from "../../images/startup.svg"
import customizeSvg from "../../images/customize.svg"
import monitorSvg from "../../images/monitor.svg"
import { Box, Image, Heading, Paragraph, ResponsiveContext } from "grommet"
import ActionButton from "../common/actionButton"

const OurHelp = () => {
  const size = React.useContext(ResponsiveContext)

  const basis = {
    basis: size === "small" ? "1" : "1/3",
  }

  return (
    <Box align="center" margin={{ bottom: "large" }}>
      <Box
        pad={{ horizontal: "xlarge", vertical: "xlarge" }}
        align="center"
        fill
      >
        <Heading level="2" margin="none" textAlign="center">
          Rinnova la tua strategia di raccolta fondi adesso!
        </Heading>
        <Paragraph size="large" textAlign="center" margin={{ bottom: "none" }}>
          Ovunque siano i tuoi donatori noi siamo pronti ad aiutarti, avrai al
          tuo fianco un team di esperti per qualsiasi evenienza.
        </Paragraph>
      </Box>
      <Box
        pad={{ horizontal: "xlarge", vertical: "large" }}
        direction={size === "small" ? "column" : "row"}
      >
        <Box
          {...basis}
          margin={{ horizontal: "large" }}
          pad={{ horizontal: "large" }}
        >
          <Image src={startupSvg} fill margin={{ bottom: "medium" }} />
          <Heading level="3" margin="none" textAlign="center">
            Startup
          </Heading>
          <Paragraph size="medium" textAlign="center">
            Ti guideremo passo passo nel processo di installazione, da zero fino
            a completamento. Sarai operativo in pochi giorni!
          </Paragraph>
        </Box>

        <Box
          {...basis}
          margin={{ horizontal: "large" }}
          pad={{ horizontal: "large" }}
        >
          <Image src={customizeSvg} fill margin={{ bottom: "medium" }} />
          <Heading level="3" margin="none" textAlign="center">
            Personalizzazione
          </Heading>
          <Paragraph size="medium" textAlign="center">
            DonationBox è completamente personalizzabile. Forniamo un servizio
            di graphic design per dare la massima visibilità al tuo brand.
          </Paragraph>
        </Box>

        <Box
          {...basis}
          margin={{ horizontal: "large" }}
          pad={{ horizontal: "large" }}
        >
          <Image
            src={monitorSvg}
            fill
            margin={{ bottom: "medium" }}
            height={100}
          />
          <Heading level="3" margin="none" textAlign="center">
            Analisi
          </Heading>
          <Paragraph size="medium" textAlign="center">
            Avrai a disposizione un portale online per controllare le donazioni
            ricevute ed importarle direttamente nel tuo database donatori.
          </Paragraph>
        </Box>
      </Box>
      <ActionButton
        alignSelf="center"
        primary
        label="Chiedi informazioni"
        to="#contatti"
      />
    </Box>
  )
}

export default OurHelp
