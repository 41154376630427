import React, { useState, useCallback } from "react"
import { Box, Heading, Paragraph, Text } from "grommet"
import ContactForm from "./form/contactForm"
import axios from "axios"
import { useQueryParam, StringParam } from 'use-query-params';

const API_URL = "https://workplace.metadonors.it/api/crm/v1/leads"

const submitForm = values => {
  return new Promise((res, rej) => {
    setTimeout(() => {
      axios
        .post(API_URL, values)
        .then(r => res(r))
        .catch(e => rej(e))
    }, 2000)
  })
}

const Contacts = () => {
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(false)

  const [campaign] = useQueryParam('utm_campaign', StringParam);
  const [medium] = useQueryParam('utm_medium', StringParam);
  const [source] = useQueryParam('utm_source', StringParam);
  const handleSubmit = useCallback(async values => {
    values.campaign = campaign
    values.medium = medium
    values.source = source
    setSubmitting(true)
    try {
      //await axios.post(API_URL, values)
      setError(false)
      await submitForm(values)
      setSubmitting(false)
      setSubmitted(true)
      typeof gtag !== 'undefined' && gtag('event', 'conversion', {'send_to': 'AW-628079184/oZxnCIGdhdMBEND0vqsC'})
    } catch (e) {
      setSubmitting(false)
      setError(true)
    }
  },[campaign, medium, source])

  return (
    <>
      <a id="contatti" name="contatti"></a>
      <Box
        pad={{ horizontal: "xlarge", vertical: "large" }}
        align="center"
        background="light-2"
      >
        <Heading level="2" margin="none" textAlign="center">
          Scopri di più
        </Heading>
        <Paragraph size="large" textAlign="center">
          Se desideri avere maggiori informazioni su come fare ad entrare nel
          mondo delle donazioni contactless, non esitare, contattaci subito.
        </Paragraph>
        <Paragraph size="medium" textAlign="center">
          <b>Telefono:</b> +39 055 7478923
          <br />
          <b>Email:</b> donationbox@metadonors.it
          <br />
        </Paragraph>
        {!submitted ? (
          <>
            <ContactForm
              submitting={submitting}
              onSubmit={handleSubmit}
            />
            {error && (
              <Text color="status-critical" margin={{top: "medium"}}>
                Non è stato possibile inviare la richiesta, prova di nuovo
                oppure scrivici direttamente
              </Text>
            )}
          </>
        ) : (
          <Box>
            <Heading level="3" margin="none" textAlign="center">
              Grazie per la richiesta!
            </Heading>
            <Paragraph size="large" textAlign="center">
              Il nostro team ti contatterà il prima possibile per darti tutte le
              informazioni di cui ha bisogno
            </Paragraph>
          </Box>
        )}
      </Box>
    </>
  )
}

export default Contacts
