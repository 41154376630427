import React from "react"
import boxImg from "../../images/box.png"
import { Box, Image, Heading, ResponsiveContext } from "grommet"
import ActionButton from "../common/actionButton"

const Revolution = () => {
  const size = React.useContext(ResponsiveContext)

  const basis = {
    basis: size === "small" ? "1" : "1/2",
  }
  const catAlign = size === "small" ? "center" : "start"

  return (
    <Box
      pad={{ horizontal: "xlarge", vertical: "xlarge" }}
      background="brandDark"
      direction={size === "small" ? "column" : "row"}
    >
      <Box {...basis} height="medium" margin={{ top: "medium" }}>
        <Image src={boxImg} fit="contain" />
      </Box>

      <Box {...basis}>
        <Heading level="2">La rivoluzione della raccolta fondi</Heading>
        <Heading level="3">
          Le donation box contactless sono perfette per qualsiasi tipo di
          occasione di raccolta fondi. Un evento all’aperto, sul banco dei
          negozi amici, all’entrata di un museo o di una chiesa.
        </Heading>

        <Box align={catAlign}>
          <ActionButton
            primary
            color="brand"
            label="Richiedi un preventivo"
            to="#contatti"
          />
        </Box>
      </Box>
    </Box>
  )
}

export default Revolution
