import React from "react"
import styled from "styled-components"
import { TextInput as GTextInput, Box, Text } from "grommet"

const StyledTextInput = styled(GTextInput)`
  border: none;
  background: #fff;
  padding: 16px 24px;
  flexGrow: 2;
`

const TextInput = ({ label, error, ...props }) => {
  return (
    <Box margin={{ bottom: "medium" }}>
      <Text>{label}</Text>
      <StyledTextInput {...props} pad="medium"/>
      {error && (
        <Text color="status-critical">
          {error}
        </Text>
      )}
    </Box>
  )
}

export default TextInput
