import React from 'react'
import { Box, ResponsiveContext } from 'grommet'

const Container = ({ children, ...props}) => {
  const size = React.useContext(ResponsiveContext)

  const hPadding = size === "small" ? "small" : "xlarge"
  const innerHPadding = size === "small" ? "small" : "large"

  return (
    <Box
        pad={{ horizontal: hPadding, vertical: "xlarge" }}
        align="center"
        {...props}
      >
        <Box margin={{ horizontal: innerHPadding }}>
          {children}
        </Box>
    </Box>
  )
}

export default React.memo(Container)