import React from "react"
import logoDraw from "../../images/logo-draw.svg"
import moneyDraw from "../../images/money-draw.svg"
import creditcardDraw from "../../images/creditcard.svg"
import { Box, Image, Heading, Paragraph, ResponsiveContext } from "grommet"
import Container from "../common/container"

const Features = () => {
  const size = React.useContext(ResponsiveContext)
  const textAlign = {
    textAlign: size === "small" ? "center" : "start",
  }

  const basis = {
    basis: size === "small" ? "1" : "1/2",
  }

  return (
    <Container align="center">
      <Box direction="row" pad={{ vertical: "large" }}>
        <Box {...basis}>
          {size === "small" && (
            <Box pad={{ vertical: "large" }}>
              <Image src={logoDraw} />
            </Box>
          )}
          <Heading level="3" margin={{ bottom: "none" }} {...textAlign}>
            Personalizzabile
          </Heading>
          <Paragraph size="large" {...textAlign}>
            Usa e diffondi il brand della tua organizzazione per essere
            riconoscibile e raccogliere donazioni immediatamente.
          </Paragraph>
        </Box>
        {size !== "small" && (
          <Box {...basis}>
            <Image src={logoDraw} />
          </Box>
        )}
      </Box>

      <Box direction="row" pad={{ vertical: "large" }}>
        {size !== "small" && (
          <Box {...basis}>
            <Image src={moneyDraw} />
          </Box>
        )}
        <Box {...basis} {...textAlign}>
          {size === "small" && (
            <Box pad={{ vertical: "large" }}>
              <Image src={moneyDraw} />
            </Box>
          )}
          <Heading level="3" margin={{ bottom: "none" }} {...textAlign}>
            Poca spesa
          </Heading>
          <Paragraph size="large" {...textAlign}>
            I costi contenuti del noleggio di DonorBox abbattono l’investimento
            di startup e non impattano sulla tua pianificazione globale.
          </Paragraph>
        </Box>
      </Box>

      <Box direction="row" pad={{ vertical: "large" }}>
        <Box {...basis}>
          {size === "small" && (
            <Box pad={{ vertical: "large" }}>
              <Image src={creditcardDraw} />
            </Box>
          )}
          <Heading level="3" margin={{ bottom: "none" }} {...textAlign}>
            Facile gestione
          </Heading>
          <Paragraph size="large" {...textAlign}>
            Senza passaggio di contanti conquisterai la fiducia dei più scettici
            e non avrai costi di logistica e sicurezza.
          </Paragraph>
        </Box>
        {size !== "small" && (
          <Box {...basis}>
            <Image src={creditcardDraw} />
          </Box>
        )}
      </Box>
    </Container>
  )
}

export default Features
