import React from "react"
import donationBoxImg from "../../images/donationbox.png"
import { Box, Image, Heading, Paragraph, ResponsiveContext } from "grommet"
import ActionButton from "../common/actionButton"
import Container from "../common/container"

const Hero = () => {
  const size = React.useContext(ResponsiveContext)
  const textAlign = {
    textAlign: size === "small" ? "center" : "start",
  }

  const catAlign = size === "small" ? "center" : "start"

  return (
    <>
      <Container background="brand">
        <Box direction="row">
          <Box basis={size === "small" ? "1" : "2/3"}>
            {size === "small" && (
              <Box align="center">
                <Image src={donationBoxImg} />
              </Box>
            )}

            <Heading margin={{ vertical: "small" }} level="1" {...textAlign}>
              Donare è ancora più facile!
            </Heading>

            <Heading margin="none" level="3" {...textAlign}>
              Il nuovo sistema per organizzazioni non profit con cui accettare
              donazioni contactless
            </Heading>

            <Paragraph size="xlarge" {...textAlign} fill>
              Crea un nuovo canale di acquisizione donazioni. Semplice e
              veloce da implementare, ti permette moltiplicare i punti di
              contatto con i tuoi donatori!
            </Paragraph>
            <Box align={catAlign}>
              <ActionButton primary label="Scrivici adesso! " to="#contatti" />
            </Box>
          </Box>

          {size !== "small" && (
            <Box basis={size === "small" ? "1" : "1/2"} height="medium">
              <Image src={donationBoxImg} fit="contain" />
            </Box>
          )}
        </Box>
      </Container>

      <Container
        background="light-2"
        align="center"
        pad={{ vertical: "large" }}
      >
        <Heading level="2" margin={{ bottom: "none" }} textAlign="center">
          Il futuro delle donazioni senza contanti e senza contatti
        </Heading>
        <Paragraph size="xlarge" textAlign="center">
          Ecco finalmente la tecnologia che da vita al tuo fundraising in un
          mondo contactless che facilita la donazione in ogni situazione.
        </Paragraph>
      </Container>
    </>
  )
}

export default Hero
