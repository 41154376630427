import React from "react"
import { useFormik } from "formik"
import TextInput from "./textInput"
import { Button, Box, Text, Form } from "grommet"
import styled from "styled-components"

const spinning = (
  <svg
    version="1.1"
    viewBox="0 0 32 32"
    width="24px"
    height="24px"
    fill="#fff"
  >
    <path
      opacity=".25"
      d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4"
    />
    <path d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z">
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 16 16"
        to="360 16 16"
        dur="0.8s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
)

export const Spinner = () => (
  <Box align="center" justify="center">
    {spinning}
  </Box>
)

const StyledButton = styled(Button)`
  padding: 20px;
`

const validate = values => {
  const errors = {}

  if (!values.name) {
    errors["name"] = "Il tuo nominativo è essenziale per ricontattarti"
  }

  if (!values.email) {
    errors["email"] = "La mail è necessaria per ricontattarti"
  }

  if (!values.phone) {
    errors["phone"] = "Il numero di telefono è necessario per riconttarti"
  }

  if (!values.company_name) {
    errors["company_name"] =
      "Sapere per chi lavori ci serve per darti un'assistenza più accurata"
  }

  return errors
}

const ContactForm = ({ onSubmit, submitting }) => {
  const formik = useFormik({
    initialValues: {
      team_alias: "fundraising@metadonors.it",
      name: "",
      email: "",
      phone: "",
      company_name: "",
    },
    validate,
    onSubmit: onSubmit,
  })
  return (
    <Form onSubmit={formik.handleSubmit}>
      <TextInput
        label="Il tuo nome"
        name="name"
        disabled={submitting}
        error={formik.touched.name && formik.errors.name && formik.errors.name}
        {...formik.getFieldProps("name")}
      />
      <TextInput
        label="La tua email"
        name="email"
        disabled={submitting}
        error={
          formik.touched.email && formik.errors.email && formik.errors.email
        }
        {...formik.getFieldProps("email")}
      />
      <TextInput
        label="Il tuo numero di telefono"
        name="phone"
        disabled={submitting}
        error={
          formik.touched.phone && formik.errors.phone && formik.errors.phone
        }
        {...formik.getFieldProps("phone")}
      />
      <TextInput
        label="Il nome della tua organizzazione"
        name="company_name"
        disabled={submitting}
        error={
          formik.touched.company_name &&
          formik.errors.company_name &&
          formik.errors.company_name
        }
        {...formik.getFieldProps("company_name")}
      />

      <StyledButton
        disabled={submitting}
        label={
          submitting ? (
            <Box direction="row" gap="small">
              {" "}
              <Spinner /> <Text size="medium"> Invio la richiesta... </Text>
            </Box>
          ) : (
            "Invia la mia richiesta di informazioni"
          )
        }
        primary
        fill
        type="submit"
      />

      
    </Form>
  )
}

export default ContactForm
